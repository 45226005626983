<template>
    <div>
        <svg
            id="robot"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            width="320px"
            height="300px"
            viewBox="0 0 160 300"
            enable-background="new 0 0 320 300"
            xml:space="preserve"
        >
            <rect
                x="59.722"
                y="72.779"
                fill="#333"
                width="40.557"
                height="27.564"
            />
            <g id="head" class="up">
                <g id="leftAntenna">
                    <path
                        fill="none"
                        stroke="#ccc"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        d="M77.519,25.869
		C75.85,13.604,65.745,3.39,53.972,3.39"
                    >
                        <animate
                            attributeName="d"
                            calcMode="spline"
                            keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1"
                            begin="0s"
                            dur="0.75s"
                            repeatCount="indefinite"
                            values="
                          M77.519,25.869C75.85,13.604,65.745,3.39,53.972,3.39
                          ;
                          M77.519,25.869C75.85,13.604,65.745,3.39,53.972,12
                          ;
                          M77.519,25.869C75.85,13.604,65.745,3.39,53.972,0
                          ;
                          M77.519,25.869C75.85,13.604,65.745,3.39,53.972,3.39
                          "
                        />
                    </path>
                    <ellipse
                        fill="#ccc"
                        cx="55.021"
                        cy="3.39"
                        rx="3.344"
                        ry="3.391"
                    >
                        <animate
                            dur="0.75s"
                            attributeName="cy"
                            calcMode="spline"
                            keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1"
                            begin="0s"
                            values="
                           3.39;
                           12;
                           0;
                           3.39
                           "
                            repeatCount="indefinite"
                        />
                    </ellipse>
                </g>
                <g id="rightAntenna">
                    <path
                        fill="none"
                        stroke="#ccc"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        d="M82.48,25.869
		C84.15,13.604,94.255,3.39,106.028,3.39"
                    >
                        <animate
                            attributeName="d"
                            calcMode="spline"
                            keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1"
                            begin="0s"
                            dur="0.6s"
                            repeatCount="indefinite"
                            values="
                          M82.48,25.869C84.15,13.604,94.255,3.39,106.028,3.39
                          ;
                          M82.48,25.869C84.15,13.604,94.255,3.39,106.028,10.39
                          ;
                          M82.48,25.869C84.15,13.604,94.255,3.39,106.028,-5.39
                          ;
                          M82.48,25.869C84.15,13.604,94.255,3.39,106.028,3.39
                          "
                        />
                    </path>
                    <ellipse
                        fill="#ccc"
                        cx="104.979"
                        cy="3.39"
                        rx="3.344"
                        ry="3.391"
                    >
                        <animate
                            dur="0.6s"
                            attributeName="cy"
                            calcMode="spline"
                            keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1"
                            begin="0s"
                            values="
                           3.39;
                           10.39;
                           -5.39;
                           3.39
                           "
                            repeatCount="indefinite"
                        />
                    </ellipse>
                </g>
                <path
                    fill="#333"
                    d="M96.079,32.57v-8.546c-10.72-3.765-21.437-3.98-32.156,0v8.546H96.079z"
                />
                <path
                    fill="#f54f59"
                    d="M112.809,28.372H80H47.19c-5.814,18.663-5.499,37.322,0,55.983H80h32.811
		C118.309,65.694,118.625,47.035,112.809,28.372z"
                />
                <g>
                    <g id="eyeLeft">
                        <path
                            fill="#FFFFFF"
                            d="M72.116,47.955c0,5.443-4.045,9.853-9.033,9.853h-1.971c-4.988,0-9.032-4.41-9.032-9.853
				s4.044-9.856,9.032-9.856h1.971C68.071,38.099,72.116,42.512,72.116,47.955z"
                        >
                            <animate
                                attributeName="d"
                                calcMode="spline"
                                keySplines="0.42 0 0.58 1; 0.42 0 0.58 1"
                                begin="5s"
                                dur="0.8s"
                                repeatCount="indefinite"
                                values="
                          M72.116,47.955c0,5.443-4.045,9.853-9.033,9.853h-1.971c-4.988,0-9.032-4.41-9.032-9.853s4.044-9.856,9.032-9.856h1.971C68.071,38.099,72.116,42.512,72.116,47.955z
                          ;
                          M72.116,47.955c0,5.443-0.045,9.853-9.033,9.853h-1.971c-4.988,0-9.032-4.41-9.032-9.853s4.044-4.856,9.032-4.856h1.971C68.071,45.099,72.116,42.512,72.116,47.955z
                          ;
                          M72.116,47.955c0,5.443-4.045,9.853-9.033,9.853h-1.971c-4.988,0-9.032-4.41-9.032-9.853s4.044-9.856,9.032-9.856h1.971C68.071,38.099,72.116,42.512,72.116,47.955z
                          "
                            />
                        </path>
                        <path
                            d="M66.614,47.955c0,2.176-1.618,3.942-3.613,3.942h-1.807c-1.994,0-3.612-1.766-3.612-3.942
				c0-2.178,1.618-3.943,3.612-3.943H63C64.996,44.012,66.614,45.777,66.614,47.955z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M107.92,47.955c0,5.443-4.045,9.853-9.031,9.853h-1.973c-4.986,0-9.031-4.41-9.031-9.853
				s4.045-9.856,9.031-9.856h1.973C103.875,38.099,107.92,42.512,107.92,47.955z"
                        >
                            <animate
                                attributeName="d"
                                calcMode="spline"
                                keySplines="0.42 0 0.58 1; 0.42 0 0.58 1"
                                begin="5s"
                                dur="0.8s"
                                repeatCount="indefinite"
                                values="
                          M107.92,47.955c0,5.443-4.045,9.853-9.031,9.853h-1.973c-4.986,0-9.031-4.41-9.031-9.853s4.045-9.856,9.031-9.856h1.973C103.875,38.099,107.92,42.512,107.92,47.955z
                          ;
                          M107.92,47.955c0,5.443-4.045,9.853-9.031,9.853h-1.973c-4.986,0-9.031-4.41-9.031-9.853s4.045-4.856,9.031-4.856h1.973C103.875,45.099,107.92,42.512,107.92,47.955z
                          ;
                          M107.92,47.955c0,5.443-4.045,9.853-9.031,9.853h-1.973c-4.986,0-9.031-4.41-9.031-9.853s4.045-9.856,9.031-9.856h1.973C103.875,38.099,107.92,42.512,107.92,47.955z
                          "
                            />
                        </path>
                        <path
                            d="M102.417,47.955c0,2.176-1.616,3.942-3.612,3.942h-1.807c-1.994,0-3.611-1.766-3.611-3.942
				c0-2.178,1.617-3.943,3.611-3.943h1.807C100.801,44.012,102.417,45.777,102.417,47.955z"
                        />
                    </g>
                </g>
                <path
                    fill="#FFFFFF"
                    d="M103.383,69.778c0,1.381-0.836,2.499-1.871,2.499c-10.756,0-32.269,0-43.025,0
		c-1.033,0-1.871-1.118-1.871-2.499c0-1.378,0.838-2.496,1.871-2.496c10.756,0,32.269,0,43.025,0
		C102.547,67.282,103.383,68.4,103.383,69.778z"
                >
                    <animate
                        attributeName="d"
                        calcMode="spline"
                        keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1"
                        begin="0s"
                        dur="1s"
                        repeatCount="indefinite"
                        values="
                          M103.383,69.778c0,1.381-0.836,2.499-1.871,2.499c-10.756,0-32.269,0-43.025,0
		c-1.033,0-1.871-1.118-1.871-2.499c0-1.378,0.838-2.496,1.871-2.496c10.756,0,32.269,0,43.025,0
		C102.547,67.282,103.383,68.4,103.383,69.778z
                          ;
                          M103.383,69.778c0,1.381-0.862,2.268-1.871,2.499c-11.669,2.677-29.396,3.207-43.025,0
		c-1.005-0.236-1.871-1.118-1.871-2.499c0-1.378,0.868-2.249,1.871-2.496c11.349-2.795,31.753-2.53,43.025,0
		C102.521,67.508,103.383,68.4,103.383,69.778z
                          ;
                          M103.383,69.778c0,1.381-0.86,2.724-1.871,2.499c-11.271-2.506-29.956-2.201-43.025,0
		c-1.019,0.171-1.871-1.118-1.871-2.499c0-1.378,0.89-2.819,1.871-2.496c15.191,4.995,30.429,3.433,43.025,0
		C102.511,67.01,103.383,68.4,103.383,69.778z
                          ;
                          M103.383,69.778c0,1.381-0.836,2.499-1.871,2.499c-10.756,0-32.269,0-43.025,0
		c-1.033,0-1.871-1.118-1.871-2.499c0-1.378,0.838-2.496,1.871-2.496c10.756,0,32.269,0,43.025,0
		C102.547,67.282,103.383,68.4,103.383,69.778z
                          "
                    />
                </path>
            </g>
            <g id="upperTorso">
                <g id="leftArm">
                    <g class="forearm">
                        <path
                            fill="#333"
                            d="M9.068,131.177c-4.78,12.558-5.183,25.372-2.497,40.71c0,0,0.68,4.312,6.107,3.39
		c4.571-0.776,4.348-5.001,4.348-5.001c-2.351-13.388-2.234-24.244,1.89-35.134c0,0,1.75-4.725-2.833-6.6
		C11.02,126.471,9.068,131.177,9.068,131.177z"
                        />
                        <path
                            fill="#f54f59"
                            d="M9.604,166.5c-6.984,1.975-11.067,9.316-9.117,16.398c1.008,3.662,3.439,6.522,6.51,8.172
		c-0.167-0.363-0.315-0.742-0.426-1.141c-1.235-4.484,1.703-9.234,6.562-10.609c4.861-1.377,9.804,1.145,11.037,5.631
		c0.111,0.396,0.18,0.798,0.221,1.197c1.785-3.021,2.399-6.748,1.393-10.407C23.833,168.661,16.589,164.523,9.604,166.5z"
                        />
                    </g>
                    <path
                        fill="#333"
                        d="M18.917,135.142c1.731-4.573,4.169-9.151,7.428-13.925c4.23-6.199,7.573-9.281,13.553-13.761
		c0,0,3.335-2.905,0.961-6.63c-2.797-4.389-7.415-1.908-7.415-1.908c-4.981,3.145-10.421,8.413-15.576,15.872
		c-3.827,5.537-6.726,10.938-8.8,16.387c0,0-1.877,4.187,2.599,6.24C16.75,139.75,18.917,135.142,18.917,135.142z"
                    />
                    <g>
                        <ellipse
                            stroke="#f54f59"
                            cx="13.581"
                            cy="132.93"
                            rx="8.505"
                            ry="8.623"
                        />
                        <g>
                            <path
                                fill="#f54f59"
                                d="M9.299,135.199c1.237,2.396,4.154,3.322,6.52,2.07c2.364-1.252,3.278-4.211,2.042-6.605
				c-1.236-2.398-4.152-3.324-6.52-2.072C8.978,129.844,8.065,132.803,9.299,135.199z"
                            />
                        </g>
                    </g>
                </g>
                <g id="rightArm">
                    <g class="forearm">
                        <path
                            fill="#333"
                            d="M143.916,128.542c-4.583,1.875-2.833,6.6-2.833,6.6c4.124,10.89,4.241,21.746,1.89,35.134
		c0,0-0.223,4.225,4.348,5.001c5.428,0.922,6.107-3.39,6.107-3.39c2.688-15.338,2.283-28.152-2.496-40.71
		C150.932,131.177,148.98,126.471,143.916,128.542z"
                        />
                        <path
                            fill="#f54f59"
                            d="M134.216,175.741c-1.006,3.659-0.392,7.386,1.394,10.407c0.041-0.399,0.109-0.801,0.221-1.197
		c1.232-4.486,6.176-7.008,11.037-5.631c4.859,1.375,7.797,6.125,6.562,10.609c-0.111,0.398-0.26,0.777-0.427,1.141
		c3.071-1.649,5.502-4.51,6.511-8.172c1.949-7.082-2.134-14.424-9.117-16.398C143.411,164.523,136.167,168.661,134.216,175.741z"
                        />
                    </g>
                    <path
                        fill="#333"
                        d="M148.333,137.417c4.476-2.054,2.599-6.24,2.599-6.24c-2.074-5.449-4.973-10.85-8.8-16.387
		c-5.155-7.459-10.595-12.727-15.576-15.872c0,0-4.618-2.48-7.415,1.908c-2.374,3.725,0.961,6.63,0.961,6.63
		c5.98,4.479,9.323,7.562,13.553,13.761c3.26,4.773,5.697,9.352,7.429,13.925C141.083,135.142,143.25,139.75,148.333,137.417z"
                    />
                    <g>
                        <ellipse
                            stroke="#f54f59"
                            cx="146.419"
                            cy="132.93"
                            rx="8.505"
                            ry="8.623"
                        />
                        <g>
                            <path
                                fill="#f54f59"
                                d="M148.659,128.592c-2.368-1.252-5.284-0.326-6.521,2.072c-1.236,2.395-0.322,5.354,2.043,6.605
				s5.282,0.326,6.52-2.07C151.936,132.803,151.021,129.844,148.659,128.592z"
                            />
                        </g>
                    </g>
                </g>
                <path
                    d="M42.356,94.049l-8.341-1.248c-5.238,10.201-7.014,20.918-4.697,32.248l8.34,1.248L42.356,94.049z"
                />
                <path
                    d="M122.342,126.297l8.34-1.248c2.317-11.33,0.541-22.047-4.697-32.248l-8.34,1.248L122.342,126.297z"
                />
                <path
                    fill="#f54f59"
                    d="M125.283,131.334c0.048-13.081-1.633-26.163-5.186-39.244H80H39.903
		c-3.552,13.081-5.232,26.162-5.184,39.242L125.283,131.334z"
                />
            </g>
            <g id="lowerTrunk">
                <g id="leftFoot">
                    <path
                        fill="#333"
                        d="M61.27,164.817c0-3.526-2.858-6.386-6.385-6.386c-3.527,0-6.386,2.859-6.386,6.386v0.001l0,0l0,36.132
			c0,3.526,2.859,6.386,6.386,6.386c3.526,0,6.385-2.859,6.385-6.386L61.27,164.817L61.27,164.817L61.27,164.817z"
                    />
                    <g class="lowerLeg">
                        <path
                            fill="#333"
                            d="M61.27,200.63c0-3.526-2.858-6.386-6.385-6.386c-3.527,0-6.386,2.859-6.386,6.386v0.001l0,0l0,36.132
			c0,3.526,2.859,6.386,6.386,6.386c3.526,0,6.385-2.859,6.385-6.386L61.27,200.63L61.27,200.63L61.27,200.63z"
                        />
                        <path
                            fill="#f54f59"
                            d="M54.885,234.096c-9.526,0-17.244,7.119-17.244,15.903H72.13C72.13,241.215,64.41,234.096,54.885,234.096z"
                        />
                    </g>
                    <g>
                        <ellipse
                            stroke="#f54f59"
                            cx="54.885"
                            cy="200.79"
                            rx="9.294"
                            ry="9.423"
                        />
                        <g>
                            <path
                                fill="#f54f59"
                                d="M60.607,203.823c-1.653,3.202-5.553,4.44-8.715,2.768c-3.163-1.677-4.383-5.628-2.73-8.832
					c1.651-3.204,5.556-4.442,8.715-2.771C61.036,196.664,62.258,200.62,60.607,203.823z"
                            />
                        </g>
                    </g>
                </g>
                <g id="rightFoot">
                    <path
                        fill="#333"
                        d="M98.73,164.817c0-3.526,2.858-6.386,6.385-6.386c3.527,0,6.386,2.859,6.386,6.386v0.001l0,0l0.001,36.132
			c0,3.526-2.859,6.386-6.387,6.386c-3.525,0-6.385-2.859-6.385-6.386V164.817L98.73,164.817L98.73,164.817z"
                    />
                    <g class="lowerLeg">
                        <path
                            fill="#333"
                            d="M98.73,200.63c0-3.526,2.858-6.386,6.385-6.386c3.527,0,6.386,2.859,6.386,6.386v0.001l0,0l0.001,36.132
			c0,3.526-2.859,6.386-6.387,6.386c-3.525,0-6.385-2.859-6.385-6.386V200.63L98.73,200.63L98.73,200.63z"
                        />
                        <path
                            fill="#f54f59"
                            d="M87.87,249.999h34.489c0-8.784-7.719-15.903-17.244-15.903S87.87,241.215,87.87,249.999z"
                        />
                    </g>
                    <g>
                        <ellipse
                            stroke="#f54f59"
                            cx="105.115"
                            cy="200.79"
                            rx="9.294"
                            ry="9.423"
                        />
                        <g>
                            <path
                                fill="#f54f59"
                                d="M102.123,194.988c3.159-1.672,7.064-0.434,8.715,2.771c1.653,3.204,0.434,7.155-2.73,8.832
					c-3.162,1.673-7.062,0.435-8.715-2.768C97.742,200.62,98.964,196.664,102.123,194.988z"
                            />
                        </g>
                    </g>
                </g>
                <path
                    fill="#f7727a"
                    d="M34.719,131.334c0.048,13.082,1.824,26.164,5.184,39.246H80h40.098c3.361-13.08,5.138-26.162,5.186-39.244L34.719,131.334z"
                />
            </g>
        </svg>
        <div class="col">
            <h1>404</h1>
            <!-- <p>... SOVOG has a configuration problem</p> -->
            <!-- <p>الصفحة  المطلوبة  غير موجودة</p> -->
            <div class="mt-10">
                <h4>Opps ! Page not found</h4>
                <p>
                    Sorry, the page you're looking for doesn't exist. If you think
                    something is broken, report a problem.
                </p>
            </div>
            <v-btn to="/" tile dark class="secondary btn mt-4" depressed>GO TO HOME</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            window.history.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
$color-base: #404853;

svg#robot {
    display: block;
    position: relative;
    margin: 6em auto 0 auto;
    padding: 10px;
    transform-origin: bottom;
    transform: rotate(0deg) translate3d(0px, 0px, 0px);
    animation: move 2s ease infinite;
}

@keyframes move {
    0%,
    100% {
        transform: rotate(0deg) translate3d(0px, 0px, 0px);
    }
    25% {
        transform: rotate(5deg) translate3d(5px, 5px, 0px);
    }
    75% {
        transform: rotate(-6deg) translate3d(-5px, 5px, 0px);
    }
}

g#head {
    transform: rotate(0deg) translate3d(0px, 0px, 0px);
    transform-origin: bottom center;
    animation: head 1s 1s ease infinite;
    #leftAntenna,
    #rightAntenna {
        > ellipse {
            animation: color 350ms linear infinite;
        }
    }
}

@keyframes head {
    0%,
    46%,
    50%,
    55%,
    100% {
        transform: rotate(0deg) translate3d(0px, 0px, 0px);
    }
    49% {
        transform: rotate(20deg) translate3d(0px, 0px, 0px);
    }
    51% {
        transform: rotate(-10deg) translate3d(0px, 0px, 0px);
    }
}

@keyframes color {
    0%,
    100% {
        fill: #ccc;
    }
    50% {
        fill: rgb(255, 0, 0);
    }
}

#upperTorso {
    transform: rotate(0deg);
    transform-origin: center;
    animation: torso 600ms 1s ease infinite;
    #leftArm {
        transform: rotate(0deg);
        transform-origin: 80% 5%;
        animation: left 1s ease infinite;
        .forearm {
            transform: rotate(0deg);
            transform-origin: 68% 10%;
            animation: forearm 800ms 1s ease infinite;
        }
    }
    #rightArm {
        transform: rotate(0deg);
        transform-origin: 18% 0%;
        animation: right 1s ease infinite;
        .forearm {
            transform: rotate(0deg);
            transform-origin: 68% 10%;
            animation: forearm 700ms 1s ease infinite;
        }
    }
}

@keyframes torso {
    40%,
    50%,
    60% {
        transform: rotate(0deg);
    }
    45% {
        transform: rotate(5deg);
    }
    55% {
        transform: rotate(-5deg);
    }
}

@keyframes left {
    60% {
        transform: rotate(100deg);
    }
}

@keyframes right {
    50% {
        transform: rotate(-70deg);
    }
}

@keyframes forearm {
    0%,
    30%,
    50%,
    70%,
    100% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(25deg);
    }
    60% {
        transform: rotate(-25deg);
    }
}

#lowerTrunk {
    transform: rotate(0deg);
    transform-origin: 60% 5%;
    animation: lowerTrunk 1s ease infinite;
}

@keyframes lowerTrunk {
    0%,
    100% {
        transform: rotate(0deg) translate3d(0px, 0px, 0px);
    }
    25% {
        transform: rotate(2deg) translate3d(2px, 0px, 0px);
    }
    75% {
        transform: rotate(-2deg) translate3d(-2px, 0px, 0px);
    }
}

#leftFoot {
    transform: rotate(0deg);
    transform-origin: 60% 5%;
    animation: leftFoot 2s ease infinite;
    > .lowerLeg {
        transform: rotate(0deg);
        transform-origin: 68% 10%;
        animation: lowerLeg 2s ease infinite;
    }
}

@keyframes leftFoot {
    0%,
    50% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(70deg);
    }
}

@keyframes lowerLeg {
    0%,
    50% {
        transform: rotate(0deg);
    }
    8%,
    14% {
        transform: rotate(-15deg);
    }
    11%,
    18% {
        transform: rotate(15deg);
    }
}

#rightFoot {
    transform: rotate(0deg);
    transform-origin: 50% 5%;
    animation: rightFoot 2s ease infinite;
    > .lowerLeg {
        transform: rotate(0deg);
        transform-origin: 68% 10%;
        animation: lowerLegIz 2s ease infinite;
    }
}

@keyframes rightFoot {
    75% {
        transform: rotate(-60deg);
    }
    50%,
    100% {
        transform: rotate(0deg);
    }
}

@keyframes lowerLegIz {
    50%,
    100% {
        transform: rotate(0deg);
    }
    60%,
    70% {
        transform: rotate(15deg);
    }
    65%,
    85% {
        transform: rotate(-15deg);
    }
}

.col {
    text-align: center;
    h1 {
        text-shadow: 0 3px 0px $color-base, 0 6px 0px #333;
        color: #f54f59;
        font-size: 6em;
        font-weight: 700;
        line-height: 0.6em;
    }
    p {
        font-family: monospace;
        font-size: 0.95em;
        font-weight: 300;
    }
}
</style>
